import { useState } from "react";
import Image from "next/image";
import { cn } from "@/lib/utils";
import Tag from "@/components/shared/Tag";

const faqs = [
  {
    question: "How does Crayo work?",
    answer:
      "Crayo is your all-in-one AI superpower for creating monetizable short-form content. We have workflows that do all the editing for you in over 5 niches, along with tools for you to make voiceovers, subtitles, and more.",
    link: "https://google.com",
  },
  {
    question: "Does Crayo.ai require any video editing skills?",
    answer:
      "No, Crayo.ai eliminates the need for complex video editing software or advanced video editing skills. All you need is your imagination and our easy-to-use tools to create engaging content.",
    link: "https://google.com",
  },
  {
    question: "What types of videos can I create?",
    answer:
      "The possibilities are endless! You can create fake-text videos, reddit stories, split-screen content, and more. Crayo also has a built-in video editor to help you trim, crop, and add text to your videos.",
    link: "https://google.com",
  },
  {
    question: "Who can benefit most from Crayo?",
    answer:
      "Our clipping tools cater to a wide-variety of audiences, including social media influencers, content creators, or simply clippers looking to earn extra money from generating content. Our tools are also useful for creating video ads, product demonstrations, or educational clips.",
    link: "https://google.com",
  },
  {
    question: "Is the content generated by Crayo.ai copyright-free?",
    answer:
      "The music and sound effects provided by Crayo.ai are royalty-free for commercial use. However, it’s important to consult our terms of service for specific details regarding copyright ownership of the generated video content.",
    link: "https://google.com",
  },
  {
    question: "Can I add captions?",
    answer:
      "Absolutely! In fact, Crayo automatically adds captions for you with over 97% accuracy! You can change text and edit it freely.",
    link: "https://google.com",
  },
  {
    question: "Is Crayo free to use?",
    answer:
      "Crayo does not offer a free tier at this time. You can purchase a Hobby, Clipper, or Pro tier by visiting our Pricing page.",
    link: "https://google.com",
  },
  {
    question: "I have more questions!",
    answer:
      "Please join our Discord or email us at support@crayo.ai if you need help or have any questions or advice for us.",
    link: "https://google.com",
  },
];

interface FAQProps {
  isPricing?: boolean;
}

const FAQ: React.FC<FAQProps> = ({ isPricing = false }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  return (
    <div
      className={cn(
        "flex w-full items-start pb-16 pt-3 md:pt-8",
        isPricing
          ? "flex-col justify-between bg-white px-8 text-center lg:flex-row lg:px-40 lg:text-start"
          : "flex-col space-y-12 bg-gradient-to-b from-[#ffffff] to-white px-8 text-center lg:px-40",
      )}
    >
      <div
        className={cn(
          "flex flex-col items-center space-y-5",
          isPricing ? "mb-4 w-full lg:mb-0 lg:w-2/3" : "w-full",
        )}
      >
        {!isPricing && <Tag label="FAQ" icon="https://cdn-crayo.com/lp/public/iconLightbulb.png" />}
        <h3 className="text-[32px] leading-[140%] md:text-[48px]">
          {isPricing ? "Frequently asked questions" : "Your questions, answered."}
        </h3>
      </div>
      <div className="flex w-full flex-col items-center justify-center gap-4 lg:gap-6">
        {faqs.map((faq, index) => (
          <button
            key={index}
            onClick={() => setOpenIndex(openIndex === index ? null : index)}
            className={cn(
              "w-full items-center overflow-hidden rounded-3xl border border-crayoBlue/20 p-6 text-start transition-all duration-300 ease-in-out hover:bg-crayoBlue/5 md:w-[680px]",
              isPricing ? "bg-crayoBlue/5" : "bg-white",
            )}
          >
            <div className="flex w-full flex-row items-center justify-between space-x-4">
              <p className="text-md font-ambit-semibold transition-all duration-500 ease-in-out hover:text-slate-600 md:text-lg">
                {faq.question}
              </p>
              <div className="rounded-full bg-crayoBlue/10 p-2 transition-all duration-200 hover:opacity-70">
                <Image
                  src="https://cdn-crayo.com/lp/public/plusBlue.png"
                  alt="view"
                  width={8}
                  height={8}
                  className={`transition-transform duration-300 ${openIndex === index ? "rotate-45" : ""}`}
                />
              </div>
            </div>
            <div
              className={`transition-all duration-300 ease-in-out ${
                openIndex === index
                  ? "visible mt-2 max-h-[1000px] opacity-100"
                  : "invisible mt-0 max-h-0 opacity-0"
              }`}
            >
              <p className="text-md font-ambit-regular text-[16px] text-slate-600 md:text-lg">
                {faq.answer}
              </p>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
